import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// TODO

const Project = () => {
	const experienceData = useStaticQuery(graphql`
		query experienceDataQuery {
			homedefaultJson(id: { eq: "experience" }) {
				title
				description
				list {
					description
					name
					time
				}
			}
		}
	`);
	const Title = experienceData.homedefaultJson.title;
	const Description = experienceData.homedefaultJson.description;
	const List = experienceData.homedefaultJson.list;
	return (
		<div className='rn-portfolio-area pt--200 pb--150 bg-color-grey portfolio-style-1'>
			<div className='container'>
				<div className='row mb--10'>
					<div className='col-lg-12'>
						<div className='section-title'>
							<h3 className='title'>
								{Title}
								<span className='bg'>{Description}</span>
							</h3>
						</div>
					</div>
				</div>
				<div className='col-lg-7'>
					<div className='inner'>
						{List.map((data) => (
							<div className='content mb--50'>
								<h5>{data.name}</h5>
								<span className='description'>{data.time}</span>
								<p className='description'>{data.description}</p>
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Project;
